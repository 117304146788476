'use strict';

angular.module('ufcw')
  .controller('ReservaactividadController', function ($rootScope,$log,$location,Request,$localstorage,$uibModal,$document,$state,toaster,$translate,EnvConfig) {
    var vm = this;
    vm.reservation_enabled = true;
    vm.carnetExpirated = false;
    vm.payExpirated = false;
    vm.payment_text = "";
    
    var id = $location.search().id; 
    var user  = $localstorage.getObject('user');
    vm.dependents = $localstorage.getObject('dependents');
    vm.has_dependent = (vm.dependents.length === 0)?false:true;
    var config  = $localstorage.getObject('config');
    vm.useBuilding = config.useBuilding;

    $localstorage.setObject('activitycustom', {});

    if(config.reservationEnabled===0){
      vm.reservation_enabled = false;
      vm.notice_title = config.noticeAlertTitle;
      vm.notice_text = config.noticeAlertText;
    }else{
      vm.reservation_enabled = true;
    }

    $rootScope.user = user;
    vm.showNextDay = true;

    if(EnvConfig.show_carnet_expiration || config.validateCarnetExpiration === 1){
      if($rootScope.user.carnetExpiration && $rootScope.user.carnetExpiration !== '0000-00-00'){
        var currentDate = new Date();
        var carnetExpirationDate = new Date($rootScope.user.carnetExpiration);
        var dateDiff = carnetExpirationDate.getTime() - currentDate.getTime();
        var diffdays = Math.round(dateDiff / (1000 * 60 * 60 * 24));
        vm.carnetExpirated = diffdays < 30 ? true:false;
        vm.carnetAlreadyExpired = diffdays < 0 ? true:false;
      }
    }
    
    if(EnvConfig.show_payment_expiration){
      if($rootScope.user.payExpiration && $rootScope.user.payExpiration !== '0000-00-00'){
        var currentDatePay = new Date();
        var payExpirationDate = new Date($rootScope.user.payExpiration);
        var dateDiffPay = payExpirationDate.getTime() - currentDatePay.getTime();
        var daysPay = Math.round(dateDiffPay / (1000 * 60 * 60 * 24));
        if($rootScope.user.payMode === 'ANUAL'){
          vm.payment_text = $translate.instant('general.payExpiration_anual_message');
          vm.payExpirated = daysPay < 15 ? true:false;
        }
        if($rootScope.user.payMode === 'MENSUAL'){
          vm.payment_text = $translate.instant('general.payExpiration_mensual_message');
          vm.payExpirated = daysPay < 0 ? true:false;
        }
      }
    }
    
    $rootScope.headLander = true;
    $rootScope.titulopagina = $translate.instant("reserve.title");
   
    var days = [$translate.instant('days.sunday'), $translate.instant('days.monday'), $translate.instant('days.tuesday'), $translate.instant('days.wednesday'), $translate.instant('days.thursday'), $translate.instant('days.friday'), $translate.instant('days.saturday')];
    var months = [$translate.instant('months.january'), $translate.instant('months.february'), $translate.instant('months.march'), $translate.instant('months.april'), $translate.instant('months.may'), $translate.instant('months.june'), $translate.instant('months.july'),$translate.instant('months.august'),$translate.instant('months.september'),$translate.instant('months.october'),$translate.instant('months.november'),$translate.instant('months.december')];
    var d = new Date();
    
    function unique(value, index, self){
      return self.indexOf(value) === index;
    }

    vm.sendNotification = function(id,action){
      var data = {"type":"email","action":action,"reservationId":id};
      Request.post('notification/',true,data,function() { });

    };
    
    vm.daycount = 0;

    vm.changeDate = function(option){
      if(option==='ADD'){
        vm.daycount+=1;
        d.setDate(d.getDate() + 1);
      }else{
        vm.daycount-=1;
        d.setDate(d.getDate() - 1);
      }
      if(vm.daycount>0){
        vm.showNextDay = false;
      }else if(vm.daycount===0){
        vm.showNextDay = true;
      }
      
      
      if (vm.maxActiveResFlag>0){
        if(d.getDay()===vm.dayStartRes){
          vm.showNextDay = false;
        }else{
          vm.showNextDay = true;
        }
      }else{
        // para habilitar siguiente día desde el viernes hasta el lunes.
        if(d.getDay()===6 || d.getDay()===0){
          vm.showNextDay = true;
        }
      }

    };

    vm.load = function(){
      // var inicio=new Date("2020-08-17 23:59:59");
      // if(d<=inicio){
        // vm.reservation_enabled=false;
      // }else{
        // vm.reservation_enabled=true;
      // }
      var dayName = days[d.getDay()];
      var dayNumber = d.getDate();

      var monthName = months[d.getMonth()];
      
      vm.day=dayName +" "+ dayNumber+ " " + $translate.instant("general.of") + " "+ monthName;

      var params = "?id="+id+"&dow="+d.getDay()+"&userId="+$rootScope.user.id;
      Request.get('activitytime/'+params,true,function(response) {
        if(response.status !== 0){
          // vm.errorMsg = $translate.instant(response.description);
          var res = response.description.substring(0, 4);
          var prefix = (res==="api.")?res:"";
          toaster.pop('error', "", $translate.instant(prefix+response.description));
        }else{
          $localstorage.setObject('config',response.config);
          var config  = $localstorage.getObject('config');
          if(config.reservationEnabled===0){
            vm.reservation_enabled = false;
            vm.notice_title = config.noticeAlertTitle;
            vm.notice_text = config.noticeAlertText;
          }

          vm.activities = response.description;
          var categories = vm.activities.map(function(item) {
            return item.CategoryName;
          });
          var filter_act = vm.activities.map(function(item) {
            return item.name;
          });
          var listbuildings = vm.activities.map(function(item) {
            return item.buildname;
          });
          
          vm.maxActiveResFlag = response.maxActiveReservation;
          if (vm.maxActiveResFlag>0){
            vm.dayStartRes = response.maxActiveReservationStartDay;
          }else{
            vm.dayStartRes = 0;
          }
          vm.categories = categories.filter(unique);
          vm.categories.sort();
          vm.filter_act = filter_act.filter(unique);
          vm.filter_act.sort();
          vm.listbuildings = listbuildings.filter(unique);
          vm.listbuildings.sort();
          vm.no_activities = (vm.activities.length === 0)?true:false;
          
          vm.show_selectCat = (vm.categories.length>1)?true:false;
          vm.show_selectAct = (vm.filter_act.length>1)?true:false;
          vm.show_selectBuild = (vm.listbuildings.length>1)?true:false;
          
          if (vm.maxActiveResFlag>0){
            if(d.getDay()===vm.dayStartRes){
              vm.showNextDay = false;
            }else{
              vm.showNextDay = true;
            }
          }else{
            // para habilitar siguiente día desde el viernes hasta el lunes.
            if(d.getDay()===6 || d.getDay()===0){
              vm.showNextDay = true;
            }
          }

        }
      });
    };

    vm.reservation = function (id,at,day,desc) {
      
      var data = {"usr":id,"at":at,"day":day,"description":desc};

      Request.post('reservation/',true,data,function(response) {
        if(response.status !== 0){
          var res = response.description.substring(0, 4);
          var prefix = (res==="api.")?res:"";
          toaster.pop('error', "", $translate.instant(prefix+response.description));
        }else{
          toaster.pop('success', "", $translate.instant("reserve.success"));
          vm.load();
          vm.sendNotification(response.description.id,'confirm');

          //if payment post to payment page
          // window.open('payment');
          ////
        }
      });
    };

    vm.cancelReservation = function (id) {
      Request.delete('reservation/?id='+id,true,function(response) {
        if(response.status !== 0){
          var res = response.description.substring(0, 4);
          var prefix = (res==="api.")?res:"";
          toaster.pop('error', "", $translate.instant(prefix+response.description));
          // toaster.pop('error', "", $translate.instant('api.'+response.description));
        }else{
          toaster.pop('success', "", $translate.instant("reserve.canceled_ok"));
          vm.load();
          vm.sendNotification(id,'cancel');
        }
      });
    };

    vm.load();
    

    // MODAL THINGS
    vm.animationsEnabled = true;
    
    vm.open = function (size, parentSelector) {      
      var modalInstance;
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
        if(vm.activity.typeReservation==="CUSTOM"){
          //Enviar a page
          vm.activity.day = vm.day;
          vm.activity.actualday = d;
          vm.activity.moredays = vm.moredays;
          vm.activity.actCategoryId = id;
          $localstorage.setObject('activitycustom', vm.activity);
          $state.go('reservaactividadcustom',{activity:vm.activity});
          return;
        }else{
          modalInstance = $uibModal.open({
              animation: vm.animationsEnabled,
              ariaLabelledBy: 'modal-title',
              ariaDescribedBy: 'modal-body',
              templateUrl: 'confirmareserva.html',
              controller: 'ModalReservaController',
              controllerAs: 'modalCtrl',
              size: size,
              backdrop: 'static',
              keyboard: false,
              appendTo: parentElem,
              resolve: {
                title: function () {
                  return vm.title;
                },
                day: function () {
                  return vm.day;
                },
                dependents: function () {
                  return vm.dependents;
                },
                action: function () {
                  return vm.action;
                },
                activity: function () {
                  return vm.activity;
                }
            }
          });
        }
        
      
  
      modalInstance.result.then(function (result) {
          if(result.status === 'OK'){
            vm.activity = result.activity;
            switch (vm.action) {
              case 'PARENT':
                vm.reservation(vm.id,vm.at,vm.moredays,vm.activity.guests);  
              break;
              case 'DEPENDENT':
                vm.reservation(result.dependent,vm.at,vm.moredays,vm.activity.guests);
              break;
              case 'CANCEL':
                vm.cancelReservation(vm.activity.reservationId);
              break;
            }
          }
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

    vm.openVirtual = function (size, parentSelector) {
      var parentElem = parentSelector ? angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'clasevirtual.html',
        controller: 'ModalVirtualController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          title: function () {
            return vm.title;
          },
          activity: function () {
            return vm.activity;
          }
        }
      });
  
      modalInstance.result.then(function () {
        
          // if(result.status === 'OK'){
          //   vm.activity = result.activity;
          //   switch (vm.action) {
          //     case 'PARENT':
          //       vm.reservation(vm.id,vm.at,vm.moredays,vm.activity.guests);  
          //     break;
          //     case 'DEPENDENT':
          //       vm.reservation(result.dependent,vm.at,vm.moredays,vm.activity.guests);
          //     break;
          //     case 'CANCEL':
          //       vm.cancelReservation(vm.activity.reservationId);
          //     break;
          //   }
          // }
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

    vm.doReservation = function(action,activity){
      vm.activity                 = activity;
      vm.activity.guests          = "";
      vm.action                   = action;
      vm.activity.typeReservation = activity.typeReservation;
      vm.at                       = vm.activity.id;
      vm.id                       = $rootScope.user.id;
      vm.moredays                 = vm.daycount;
      if(action === 'PARENT' || action === 'DEPENDENT'){
        vm.title = $translate.instant("reserve.modal_title_confirm");
      }else if(action === 'CANCEL'){
        vm.title = $translate.instant("reserve.modal_title_cancel");
      }
      
      vm.open();            
    };

    vm.showVirtual = function(activity){
      vm.title = $translate.instant("reserve.view_class");
      vm.activity = activity;
      vm.at = vm.activity.id;
      vm.id = $rootScope.user.id;
      
      vm.openVirtual();
    };

    vm.showOccupancy = function(max,total){
      if(max === -1){
        return true;
      }
      var occupancy = max-total;
      if(occupancy<1){
        return false;
      }
      return true;
    };

    vm.showInfo = function(activity, type){
      vm.activity = activity;
      vm.info_type = type;
      vm.openInfo();
    };

    vm.openInfo = function (size, parentSelector) {
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'showInfo.html',
        controller: 'ModalInfoController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          activity: function () {
            return vm.activity;
          },
          info_type: function () {
            return vm.info_type;
          }
        }
      });
  
      modalInstance.result.then(function () {
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

    vm.addToWaiting = function(activity){
      vm.activity = activity;
      vm.title = $translate.instant("reserve.add_to_waitinglist");
      vm.openWaitingList();
    };

    vm.openWaitingList = function (size, parentSelector) {
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'waitingreservation.html',
        controller: 'ModalWaitingController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          title: function () {
            return vm.title;
          },
          day: function () {
            return vm.day;
          },
          user: function () {
            return $rootScope.user;
          },
          members: function () {
            return vm.dependents;
          },
          activity: function () {
            return vm.activity;
          }
        }
      });
  
      modalInstance.result.then(function (result) {
          if(result.status === 'OK'){
            vm.activity = result.activity;
            vm.member = result.member;
            if(vm.member && vm.activity){

              var data = {"usr":vm.member,"at":vm.activity.id,"day":vm.daycount};

              Request.post('waitinglist/',true,data,function(response) {
                if(response.status !== 0){
                  toaster.pop('error', "", $translate.instant('api.'+response.description));
                }else{
                  toaster.pop('success', "", $translate.instant("reserve.waitinglist_added"));
                }
              });
            }
          }
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

    vm.openGuests = function (size, parentSelector) {
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'guests.html',
        controller: 'ModalEditGuestsController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          activity: function () {
            return vm.activity;
          }
        }
      });
  
      modalInstance.result.then(function () {
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

    vm.editGuests = function(activity){
      if(activity.id){
        Request.get('reservation/?id='+activity.reservationId,true,function(response) {
          var reservation = response.description;
          if(angular.isString(reservation.description)){
            activity.guests = reservation.description.split(',');
          }
          activity.editGuests = true;
          activity.day = vm.day;
          vm.activity = activity;
          vm.openGuests();
        });
      }
    };

  });

  angular.module('ufcw').controller('ModalReservaController', function ($uibModalInstance,$log,title,day,dependents,action,activity,toaster,$translate,Request,$localstorage) {

    var vm = this;    
    vm.title = title;
    vm.activity = activity;
    vm.day = day;
    vm.dependents = dependents;
    vm.action = action;
    vm.guest = [];
    vm.error_guest = [];
    vm.isDisabled = false;

    var d = new Date(vm.activity.reservationdate);
    var hours = d.getHours().toString(); 
    var minutes = d.getMinutes().toString(); 
    hours = (hours.length===1)?"0"+hours:hours;
    minutes = (minutes.length===1)?minutes+"0":minutes;
    vm.activity.start_custom = hours + ":" + minutes;
    
    var config  = $localstorage.getObject('config');
    vm.useBuilding = config.useBuilding;

    vm.ok = function () {
      try {
        if(vm.action === 'DEPENDENT' && !vm.selDependent){
          throw $translate.instant("reserve.must_select_dependent");
        }
        
        if(vm.guest.length > 0){
          vm.activity.guests = vm.guest.toString();
        }

        if(!vm.activity.reservationId && vm.activity.validateGuest===1){
          Request.get('user/?guests='+vm.guest.toString(),true,function(response) {
            if(response.code === "ERROR"){
              vm.error_guest = response.description;
              toaster.pop('error', "", $translate.instant("reserve.invalid_guest"));
            }else{
              $uibModalInstance.close({dependent: vm.selDependent, activity:vm.activity, status: 'OK'});
            }
          });
        }else{
          $uibModalInstance.close({dependent: vm.selDependent, activity:vm.activity, status: 'OK'});
        }

      } catch (error) {
        toaster.pop('error', "", error);
        vm.isDisabled = false;
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });

  angular.module('ufcw').controller('ModalWaitingController', function ($uibModalInstance,$log,title,day,user,members,activity,toaster) {
    var vm = this;
    
    vm.title = title;
    vm.activity = activity;
    vm.day = day;
    vm.user = user;
    vm.members = members;
    vm.has_dependent = (vm.members.length === 0)?false:true;

    vm.ok = function () {
      try {
        if(!vm.member){
          vm.member = vm.user.id;
        }
        $uibModalInstance.close({member: vm.member, activity:vm.activity, status: 'OK'});
      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });

  angular.module('ufcw').controller('ModalInfoController', function ($uibModalInstance,$log,activity,info_type,toaster) {
    var vm = this;
    
    vm.activity = activity;
    vm.info_type = info_type;
    
    
    vm.ok = function () {
      try {
        $uibModalInstance.close({status: 'OK'});
      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });

  angular.module('ufcw').controller('ModalVirtualController', function ($uibModalInstance,$log,title,activity) {
    var vm = this;
    
    vm.title = title;
    vm.activity = activity;
  
    

    vm.ok = function () {
      // try {
      //   if(vm.action === 'DEPENDENT' && !vm.selDependent){
      //     throw "Debe seleccionar un familiar";
      //   }
      //   if(vm.inv1){
      //     vm.activity.guests += ""+vm.inv1;
      //   }
      //   if(vm.inv2){
      //     vm.activity.guests += ", "+vm.inv2;
      //   }
      //   if(vm.inv3){
      //     vm.activity.guests += ", "+vm.inv3;
      //   }
      //   $uibModalInstance.close({dependent: vm.selDependent, activity:vm.activity, status: 'OK'});
      // } catch (error) {
      //   toaster.pop('error', "", error);
      // }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });
  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'clasevirtual.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.title = vm.resolve.title;
        vm.activity = vm.resolve.activity;
        vm.day = vm.resolve.day;
        vm.dependents = vm.resolve.dependents;
        vm.action = vm.resolve.action;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });


  

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'confirmareserva.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.title = vm.resolve.title;
        vm.activity = vm.resolve.activity;
        vm.day = vm.resolve.day;
        vm.dependents = vm.resolve.dependents;
        vm.action = vm.resolve.action;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'waitingreservation.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.title = vm.resolve.title;
        vm.activity = vm.resolve.activity;
        vm.day = vm.resolve.day;
        vm.user = vm.resolve.user;
        vm.members = vm.resolve.members;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'showInfo.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.activity = vm.resolve.activity;
        vm.info_type = vm.resolve.info_type;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });

  // angular.module('ufcw').controller('ModalInfoController', function ($uibModalInstance,$log,activity,toaster) {
  //   var vm = this;
    
  //   vm.activity = activity;
    
  //   vm.ok = function () {
  //     try {
  //       $uibModalInstance.close({status: 'OK'});
  //     } catch (error) {
  //       toaster.pop('error', "", error);
  //     }
  //   };
  
  //   vm.cancel = function () {
  //     $uibModalInstance.dismiss('cancel');
  //   };
  // });

  // angular.module('ufcw').component('modalComponent', {
  //   templateUrl: 'showInfo.html',
  //   bindings: {
  //     resolve: '<',
  //     close: '&',
  //     dismiss: '&'
  //   },
  //   controller: function () {
  //     var vm = this;
  
  //     vm.$onInit = function () {
  //       vm.activity = vm.resolve.activity;
  //     };
  
  //     vm.ok = function () {
  //       vm.close({$value: 'OK'});
  //     };
  //   }
  // });

  angular.module('ufcw').controller('ModalWaitingController', function ($uibModalInstance,$log,title,day,user,members,activity,toaster) {
    var vm = this;
    
    vm.title = title;
    vm.activity = activity;
    vm.day = day;
    vm.user = user;
    vm.members = members;
    vm.has_dependent = (vm.members.length === 0)?false:true;

    vm.ok = function () {
      try {
        if(!vm.member){
          vm.member = vm.user.id;
        }
        $uibModalInstance.close({member: vm.member, activity:vm.activity, status: 'OK'});
      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'waitingreservation.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.title = vm.resolve.title;
        vm.activity = vm.resolve.activity;
        vm.day = vm.resolve.day;
        vm.user = vm.resolve.user;
        vm.members = vm.resolve.members;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });

  angular.module('ufcw').controller('ModalEditGuestsController', function ($uibModalInstance,$log,Request,toaster,$translate,activity) {
    var vm = this;
    
    vm.activity = activity;
    vm.guests = activity.guests;
    
    vm.action = $translate.instant("general.view_action");
    
    if(vm.activity.editGuests){
      vm.action = $translate.instant("general.edit_action");
    }
    // vm.activity.typeReservation === 'CUSTOM' && 
    if(vm.activity.guestQty === 0 && vm.guests.length > 0){
      vm.activity.guestQty = vm.guests.length;
    }

    if(!activity.reservationId){
      activity.reservationId = activity.id;
    }

    vm.ok = function () {
      if(vm.activity.editGuests){
        if(vm.activity.validateGuest===1){
          Request.get('user/?guests='+vm.guests.toString(),true,function(response) {
            if(response.code === "ERROR"){
              vm.error_guest = response.description;
              toaster.pop('error', "", $translate.instant("reserve.invalid_guest"));
            }else{
              vm.updateReservation(vm.activity.reservationId,vm.guests);
              $uibModalInstance.close({status: 'OK'});
            }
          });
        }else{
          vm.updateReservation(vm.activity.reservationId,vm.guests);
          $uibModalInstance.close({status: 'OK'});
        }
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    vm.updateReservation = function(id,guests){

      var data = {description: guests.toString()};
      Request.put('reservation/?id='+id,true,data,function(response) {
        if(response.status !== 0){
          toaster.pop('error', "", $translate.instant('api.'+response.description));
        }else{
          toaster.pop('success', "", $translate.instant("reserve.reservation_updated"));
        }
      });

    };

  });

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'guests.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.activity = vm.resolve.activity;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });